import { useCompany } from '@/hooks/useSetCompanyInAtom';
import { Company } from '@/types/schema';

type AccountType = 'CLOVER' | 'STRIPE';

export default function useAccountType( type: AccountType, sentCompany?: Company | undefined ): boolean {
	const { company } = useCompany();
	
	const currentCompany = sentCompany || company;
	
	return Boolean( currentCompany?.gateways?.find( ( gateway ) => gateway?.active && gateway?.external === type ) );
}
