import { Box, OutlinedTextFieldProps, TextField, TextFieldProps } from '@mui/material';
import { ComponentType, forwardRef } from 'react';
import TextFieldInputLabel, { TextFieldInputLabelProps } from '../form/inputLabel';

export type DateTextFieldSlotProps = Omit<TextFieldInputLabelProps, 'label'> & TextFieldProps;

const DateTextFieldSlot = forwardRef<HTMLInputElement, DateTextFieldSlotProps>( ( props, ref ) => {
	const { isError, label, tooltip, ...textFieldProps } = props;
	
	return (
		<Box width='100%'>
			<TextFieldInputLabel
				isError={isError}
				label={label}
				tooltip={tooltip}
			/>
			<TextField
				{...textFieldProps}
				inputRef={ref}
			/>
		</Box>
	);
} );

export default DateTextFieldSlot as ComponentType<OutlinedTextFieldProps>;
