import { pick } from 'lodash-es';
import { validate } from 'uuid';

type Many<T> = T | ReadonlyArray<T>;

export default function idPick<T extends { id?: string }, U extends keyof T>( object: T,
	...props: Array<Many<U>> ): Pick<T, U> {
	if ( validate( object.id ) ) {
		props.push( 'id' as any );
	}
	return pick( object, ...props );
}
