import usePermissions from '@/providers/auth/usePermissions';
import { usePopper } from '@/providers/popper';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Grid, IconButton, MenuItem, MenuList, Pagination, Stack, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import type { TableInstance } from 'react-table';

export default function GraphqlTableFooter( {
	table,
	renderDeletedData,
	pageSizeOptions,
}: {
	table: TableInstance,
	pageSizeOptions: number[],
	renderDeletedData?: () => ReactNode
} ) {
	const { showPopper } = usePopper();
	const isOwner = usePermissions( [ 'OWNER' ] );
	const { t } = useTranslation();
	
	return (
		<Grid container spacing={1} mt={.5}>
			<Grid item xs={12} sm={6}>
				<Stack direction='row' alignItems='center'>
					<Typography>{t( 'common:rows-per-page' )}:</Typography>
					<Button
						component='li'
						variant='text'
						sx={{
							'color'       : 'text.primary',
							'fontWeight'  : 'normal',
							'minWidth'    : 32,
							'height'      : 32,
							'borderRadius': '16px',
							'ml'          : 1,
							'padding'     : '0 6px',
							'bgcolor'     : 'divider',
							':hover'      : {
								filter : 'brightness(80%)',
								bgcolor: 'divider',
							},
						}}
						onClick={( e ) => showPopper( ( { closePopper } ) => (
							<MenuList>
								{pageSizeOptions.map( ( count, index ) => (
									<MenuItem
										key={index}
										selected={count === table.state.pageSize}
										sx={{ m: 0 }}
										onClick={() => {
											table.setPageSize( count );
											closePopper();
										}}>
										{count}
									</MenuItem>
								) )}
							</MenuList>
						), e.currentTarget )}>
						{table.state.pageSize}
					</Button>
					{isOwner && Boolean( renderDeletedData ) && (
						<Tooltip title='Recover deleted documents'>
							<IconButton
								sx={{ ml: 1 }}
								onClick={renderDeletedData}>
								<DeleteIcon fontSize='small'/>
							</IconButton>
						</Tooltip>
					)}
				</Stack>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
				sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'end' } }}>
				<Pagination
					sx={{ textAlign: 'right' }}
					page={table.state.pageIndex + 1}
					count={table.pageCount}
					onChange={( event, value ) => table.gotoPage( value - 1 )}
				/>
			</Grid>
		</Grid>
	);
}
