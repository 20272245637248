import type { TooltipProps, TypographyProps } from '@mui/material';
import { Tooltip, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import useEventEffect from '../hooks/useEventEffect';

export default function OverflowTypography( {
	children,
	tooltipProps,
	...props
}: {
	tooltipProps?: TooltipProps
} & TypographyProps ) {
	const contentRef = useRef<HTMLElement>();
	
	const [ overFlowed, setOverFlowed ] = useState( false );
	
	useEventEffect( window, 'resize', () => {
		if ( contentRef.current ) setOverFlowed( contentRef.current.scrollWidth > contentRef.current.clientWidth );
	}, [ contentRef.current ], true );
	
	if ( !children ) return null;
	
	return (
		<Tooltip arrow title={children} disableHoverListener={!overFlowed} {...tooltipProps}>
			<Typography ref={contentRef} noWrap {...props}>
				{children}
			</Typography>
		</Tooltip>
	);
}
