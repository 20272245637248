import { Box, Button, Stack, Typography } from '@mui/material';
import { map } from 'lodash-es';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ColumnInstance } from 'react-table';
import FilterAccordion from './filterAccordion';

export default function Filters( {
	columns,
	setAllFilters,
	filters,
	closeMenu,
}: {
	columns: ColumnInstance<any>[],
	setAllFilters: ( filters: { id: string, value: any }[] ) => void,
	filters: { id: string, value: any }[],
	closeMenu: () => void
} ) {
	const { t } = useTranslation();
	const [ clearChecks, setClearChecks ] = useState( true );
	const [ columnFilters, setColumnFilters ] = useState( () =>
		filters.reduce( ( obj, curr ) => ( { ...obj, [ curr.id ]: curr.value } ), {} ),
	);
	
	const clearFilters = () => {
		setAllFilters( [] );
		setColumnFilters( [] );
		setClearChecks( false );
	};
	
	const sendFilterQuery = () => {
		const arr = map( columnFilters, ( val, key ) => ( { id: key, value: val } ) );
		if ( !arr ) return;
		setAllFilters( arr );
		closeMenu();
	};
	
	return (
		<Box
			sx={{
				overflow : 'overlay',
				maxHeight: 'calc(100vh - env(safe-area-inset-top) - 300px)',
				position : 'relative',
			}}>
			<Stack
				direction='row'
				justifyContent='space-between'
				sx={{
					px          : 1,
					py          : 2,
					position    : 'sticky',
					top         : 0,
					left        : 0,
					right       : 0,
					zIndex      : 2,
					borderBottom: 1,
					borderColor : 'divider',
					bgcolor     : 'background.paper',
				}}>
				<Button
					variant='outlined'
					color='primary'
					onClick={clearFilters}>
					{t( 'common:clear-all' )}
				</Button>
				<Typography variant='h5' sx={{ fontWeight: 500 }}>
					{t( 'common:filter' )}
				</Typography>
				<Button
					variant='contained'
					color='primary'
					onClick={sendFilterQuery}>
					{t( 'common:apply' )}
				</Button>
			</Stack>
			{columns.filter( ( column ) => column.canFilter ).map( ( column ) => {
				const filter = columnFilters[ column.id ];
				const setFilter = ( filter ) => setColumnFilters( { ...columnFilters, [ column.id ]: filter } );
				
				return (
					<Box
						key={column.id}
						sx={{
							'.MuiAccordion-root'                                 : { border: 0 },
							'.MuiList-root'                                      : { p: 0 },
							':not(:last-child)'                                  : {
								borderBottom: 1,
								borderColor : 'divider',
							},
							'.Mui-expanded.MuiAccordionSummary-gutters'          : { minHeight: 0 },
							'.Mui-expanded.MuiAccordionSummary-contentGutters'   : { my: 0 },
							'.MuiAccordionSummary-root'                          : {
								flexDirection: 'row-reverse',
								p            : 1,
								minHeight    : 20,
							},
							'.MuiAccordionSummary-expandIconWrapper.Mui-expanded': { transform: 'rotate(0deg)' },
							'.MuiAccordionSummary-content '                      : { my: 0 },
							'.MuiAccordionDetails-root'                          : { bgcolor: 'background.default' },
						}}>
						<FilterAccordion
							column={column}
							filter={filter}
							setFilter={setFilter}
							clearChecks={clearChecks}
							setClearChecks={setClearChecks}
						/>
					</Box>
				);
			} )}
		</Box>
	);
}
