import DrawerHandle from '@/components/drawerHandle';
import { SwipeableBottomSheet } from '@/providers/modal/responsiveModal';
import {
	Box,
	ClickAwayListener,
	Grow,
	Paper,
	Popper,
	PopperProps,
	SwipeableDrawerProps,
	type Theme,
	useMediaQuery,
} from '@mui/material';
import React, { ComponentType, createContext, useCallback, useContext, useRef, useState } from 'react';

type C = {
	showPopper: (
		Component: ComponentType<{ closePopper: () => void }>,
		element: Element | string,
		props?: Partial<Omit<PopperProps & SwipeableDrawerProps, 'open' | 'onClose' | 'onOpen'>>,
	) => void,
	closePopper?: () => void
};

const PopperContext = createContext<C>( {
	showPopper : () => null,
	closePopper: () => null,
} );
PopperContext.displayName = 'Popper';

export default function PopperProvider( { children } ) {
	const wide = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.up( 'sm' ) );
	const [ open, setOpen ] = useState( false );
	const [ anchorEl, setAnchorEl ] = useState<Element | null>( null );
	const [ Component, setComponent ] = useState<ComponentType<{ closePopper: () => void }>>();
	const [ props, setProps ] = useState<Partial<PopperProps & SwipeableDrawerProps>>( {} );
	const lastClickTime = useRef<number>( 0 );
	
	const togglePopper = useCallback( ( Component: ComponentType<{ closePopper: () => void }>,
		element: Element,
		props: Partial<Omit<PopperProps & SwipeableDrawerProps, 'open' | 'onClose' | 'onOpen'>> ) => {
		const now = Date.now();
		const quickSuccession = now - lastClickTime.current < 300; // less than 300ms considered as spam
		lastClickTime.current = now;
		
		if ( open && anchorEl === element ) {
			if ( quickSuccession ) {
				// If quickly clicked, close and reopen immediately
				setOpen( false );
				setTimeout( () => {
					setComponent( () => Component );
					setAnchorEl( element );
					setProps( props );
					setOpen( true );
				}, 10 );
			} else {
				// Normal behavior: just toggle off
				setOpen( false );
				setAnchorEl( null );
			}
		} else {
			setComponent( () => Component );
			setAnchorEl( element );
			setProps( props );
			setOpen( true );
		}
	}, [ open, anchorEl ] );
	
	return (
		<PopperContext.Provider
			value={{
				showPopper : togglePopper,
				closePopper: () => {
					setOpen( false );
					setAnchorEl( null );
				},
			}}>
			{wide ? (
				<Popper
					transition
					id={open ? 'transition-popper' : undefined}
					open={open}
					anchorEl={anchorEl}
					{...props}
					style={{ zIndex: 1300, ...props?.style }}>
					{( { TransitionProps } ) => (
						<Grow {...TransitionProps}>
							<Paper>
								<ClickAwayListener
									onClickAway={() => {
										if ( Date.now() - lastClickTime.current > 300 ) { // Avoid closing on rapid clicks
											setOpen( false );
											setAnchorEl( null );
										}
									}}>
									<Box>
										<Component
											closePopper={() => {
												setOpen( false );
												setAnchorEl( null );
											}}
										/>
									</Box>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			) : (
				<SwipeableBottomSheet
					open={Boolean( open )}
					onClose={() => {
						setOpen( false );
						setAnchorEl( null );
					}}
					onOpen={() => null}
					{...props}>
					<DrawerHandle/>
					{Component && (
						<Component
							closePopper={() => {
								setOpen( false );
								setAnchorEl( null );
							}}
						/>
					)}
				</SwipeableBottomSheet>
			)}
			{children}
		</PopperContext.Provider>
	);
}

export function usePopper() {
	return useContext( PopperContext );
}
