import { mutateGraphQL } from '@/data/apollo';
import { StaffTableWrite } from '@/graphql/staff.graphql';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useMenu } from '@/providers/menu';
import { Button, MenuItem, MenuList, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { MutationStaffTableWriteArgs } from '../../../types/schema';

export default function SavedFiltersButton( { tableSavedFilters }: { tableSavedFilters: string[] } ) {
	const { staff } = useUserInfo();
	const { showMenu } = useMenu();
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	
	const tableType = tableSavedFilters?.[ 0 ];
	
	const label = t( `common:${( staff?.metadata?.[ tableSavedFilters[ 0 ] ] || tableSavedFilters[ 1 ] )
		.toLowerCase()
		.split( ' ' )
		.join( '-' )}` );
	
	return (
		<Tooltip title={label}>
			<Button
				variant='outlined'
				sx={{
					textTransform: 'none',
					animation    : staff?.metadata?.[ tableType ] && staff?.metadata?.[ tableType ] !== 'Show All'
						? 'ripple2 5s linear infinite'
						: undefined,
				}}
				onClick={( e ) => showMenu( ( { closeMenu } ) => (
					<MenuList sx={{ p: 0 }}>
						{tableSavedFilters.slice( 1 ).filter( Boolean ).map( ( item, index ) => (
							<MenuItem
								key={index}
								selected={staff?.metadata?.[ tableType ] === item}
								onClick={async () => {
									await mutateGraphQL<MutationStaffTableWriteArgs>( {
										mutation : StaffTableWrite,
										variables: {
											id   : staff.id,
											input: { metadata: { [ tableType ]: item } },
										},
									} );
									await queryClient.invalidateQueries( [ 'user' ] );
									closeMenu();
								}}>
								{t( `common:${item.toLowerCase().split( ' ' ).join( '-' )}` )}
							</MenuItem>
						) )}
					</MenuList>
				), e.currentTarget )}>
				{label}
			</Button>
		</Tooltip>
	);
}

