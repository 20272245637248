import { FormControl, MenuItem, Select, Stack, TextField, TextFieldProps } from '@mui/material';
import type { ColumnInstance } from 'react-table';

interface NumberFilterProps extends TextFieldProps {
	filter: {
		temp: { value: string, priceOperatorValue: string },
		$gte: string,
		$lte: string,
		$eq: string,
		$gt: string,
		$lt: string
	},
	setFilter: ( filter: any ) => void,
	column?: ColumnInstance<any>
}

export default function NumberFilter( {
	filter,
	setFilter,
	column,
	...props
}: NumberFilterProps ) {
	const { rows, dispatch, ...textFieldProps } = props;
	
	return (
		<Stack direction='row' spacing={1} alignItems='center'>
			<FormControl sx={{ width: 130 }}>
				<Select
					autoWidth
					value={filter?.temp?.priceOperatorValue || '$eq'}
					onChange={( e ) => {
						setFilter( {
							temp              : { ...filter?.temp, priceOperatorValue: e.target.value },
							[ e.target.value ]: Number( filter?.temp?.value ),
						} );
					}}>
					<MenuItem value='$eq'>Equal To</MenuItem>
					<MenuItem value='$gt'>More Than</MenuItem>
					<MenuItem value='$gte'>At Least</MenuItem>
					<MenuItem value='$lt'>Less Than</MenuItem>
					<MenuItem value='$lte'>Up To</MenuItem>
				</Select>
			</FormControl>
			<TextField
				variant='outlined'
				placeholder={`Enter ${column?.sortType === 'number' && column?.id}...`}
				value={filter?.temp?.value || ''}
				onChange={( e ) => {
					setFilter( {
						...filter,
						temp                                         : { ...filter?.temp, value: e.target.value },
						[ filter?.temp?.priceOperatorValue || '$eq' ]: Number( e.target.value ),
					} );
				}}
				{...textFieldProps}
			/>
		</Stack>
	);
}
