import { useGraphQL } from '@/data';
import { CompanyRead } from '@/data/company.graphql';
import { useCompanyId } from '@/providers/auth/useSessionStorageCompanyId';
import { useEvents } from '@/providers/event';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

export function useCompany() {
	const { sessionCompanyId } = useCompanyId();
	const queryClient = useQueryClient();
	const appEvent = useEvents();
	
	const { data, isLoading } = useGraphQL( {
		queryKey: [ 'company' ],
		query   : CompanyRead,
	}, { enabled: sessionCompanyId !== 'null' && Boolean( sessionCompanyId ) } );
	
	useEffect( () => {
		if ( !sessionCompanyId ) return;
		const event = `SUBSCRIPTION:${sessionCompanyId}`;
		const listener = () => {
			queryClient.invalidateQueries( [ 'company' ] );
		};
		appEvent.on( event, listener );
		
		return () => {
			appEvent.off( event, listener );
		};
	}, [ sessionCompanyId ] );
	
	return { company: data?.companyRead, loading: isLoading };
}
