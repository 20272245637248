import { FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { upperFirst } from 'lodash-es';
import type { ChangeEvent } from 'react';
import { useEffect } from 'react';

export default function BooleanFilter( {
	filter,
	setFilter,
	column,
}: {
	filter: { $eq, temp },
	setFilter: ( filterValue: any ) => void,
	column
} ) {
	const options = column?.radioButtonOptions;
	
	useEffect( () => {
		if ( !filter?.hasOwnProperty( '$eq' ) || filter?.$eq === undefined ) {
			setFilter( { ...filter, $eq: null } );
		}
	}, [ filter?.temp ] );
	
	const handleChange = ( event: ChangeEvent<HTMLInputElement> ) => {
		if ( event.target.value === options[ 0 ] ) {
			setFilter( { ...filter, $eq: true } );
		} else {
			setFilter( { ...filter, $eq: null } );
		}
	};
	
	return (
		<FormControl>
			<RadioGroup
				value={filter?.$eq ? options[ 0 ] : options[ 1 ] || options[ 0 ]}
				onChange={handleChange}>
				<Stack direction='row'>
					<FormControlLabel value={options[ 0 ]} control={<Radio/>} label={upperFirst( options[ 0 ] )}/>
					<FormControlLabel value={options[ 1 ]} control={<Radio/>} label={upperFirst( options[ 1 ] )}/>
				</Stack>
			</RadioGroup>
		</FormControl>
	);
}
