import { queryGraphQL } from '@/data/apollo';
import type { DocumentNode } from '@apollo/client';
import { format } from 'date-fns';
import { isArray, isEmpty, omit, set } from 'lodash-es';
import { type ColumnInstance, TableInstance } from 'react-table';

export const generateCsvFilename = ( type: string ) => `${type}-${format( new Date(), 'P' )}.csv`;
export default async function csvExport(
	state: any,
	query: DocumentNode,
	type: string,
	timezone?: string,
	isGrouped?: boolean,
	table?: TableInstance ) {
	const filename = generateCsvFilename( type );
	const filter = state?.filters?.filter( Boolean )
		?.filter( ( item ) => item.value?.temp?.checked && Object.keys( item.value ).length >= 1 )
		.reduce( ( obj, curr ) => {
			const temp: any = typeof curr.value === 'string' ? curr.value : omit( curr.value, [ 'temp' ] );
			const column: ColumnInstance<any> | undefined = table?.columns?.find( ( { id } ) => id === curr.id );
			if ( column ) {
				if ( isArray( column?.filterByKey ) || isArray( curr.id ) ) {
					set( obj, '$or', temp?.$or );
				} else {
					set( obj, column?.filterByKey?.split( '.' )[ 0 ] || curr.id, temp );
				}
			} else if ( obj.hasOwnProperty( curr.id ) ) {
				if ( temp?.$or ) {
					set( obj, '$or', temp.$or );
				} else {
					set( obj, curr.id, {
						...obj[ curr.id ],
						...temp,
					} );
				}
			} else if ( temp?.$or ) {
				set( obj, '$or', temp.$or );
			} else {
				if ( !curr.id ) {
					Object.keys( temp ).forEach( ( innerKey ) => {
						if ( innerKey === 'dates' && Array.isArray( temp[ innerKey ] ) ) {
							set( obj, innerKey, temp[ innerKey ] );
						} else {
							set( obj, innerKey, temp[ innerKey ] );
						}
					} );
				} else {
					set( obj, curr.id.replace( / *\[[^\]]*]/, '' ).split( '.' )[ 0 ], !isEmpty( temp ) ? temp : null );
				}
			}
			
			return obj;
		}, {} );
	
	const data = await queryGraphQL( {
		query,
		variables: {
			options: {
				aggregateItems: isGrouped,
				search        : state?.globalFilter || undefined,
				filter,
				limit         : 10000,
				offset        : state?.pageIndex ? state?.pageIndex * state?.pageSize : 0,
				orderBy       : state?.sortBy?.length ? state?.sortBy.map( ( order ) => `${order.id}:${order.desc
						? 'DESC'
						: 'ASC'}` )
					: undefined,
			},
			timezone,
		},
	} );
	
	return { data, filename };
}
