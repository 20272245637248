import { useSlotProps } from '@mui/base/utils';
import { Box, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import {
	BaseMultiInputFieldProps,
	DateRange,
	DateRangeValidationError,
	MultiInputFieldSlotTextFieldProps,
	RangeFieldSection,
	UseDateRangeFieldProps,
} from '@mui/x-date-pickers-pro';
import {
	unstable_useMultiInputDateRangeField as useMultiInputDateRangeField,
} from '@mui/x-date-pickers-pro/MultiInputDateRangeField';
import React, { forwardRef } from 'react';
import TextFieldInputLabel from '../form/inputLabel';
import { FormattedTextFieldProps } from '../formattedTextField';
import { DateTextFieldSlotProps } from './dateTextFieldSlot';

type CustomDateRangePickerFieldsSlotProps =
	UseDateRangeFieldProps<any>
	& BaseMultiInputFieldProps<DateRange<any>, any, RangeFieldSection, DateRangeValidationError>
	& {
	formattedTextFieldPropsStart: FormattedTextFieldProps,
	formattedTextFieldPropsEnd: DateTextFieldSlotProps
};

const DateRangePickerFields = forwardRef<HTMLInputElement, CustomDateRangePickerFieldsSlotProps>( ( props, ref ) => {
	
	const { inputRef: startInputRef, ...startTextFieldProps } = useSlotProps( {
		elementType      : 'input',
		externalSlotProps: props.slotProps?.textField,
		ownerState       : { ...props, position: 'start' },
	} ) as MultiInputFieldSlotTextFieldProps;
	
	const { inputRef: endInputRef, ...endTextFieldProps } = useSlotProps( {
		elementType      : 'input',
		externalSlotProps: props.slotProps?.textField,
		ownerState       : { ...props, position: 'end' },
	} ) as MultiInputFieldSlotTextFieldProps;
	
	const {
		      startDate: { ref: startRef, ...startDateProps },
		      endDate  : { ref: endRef, ...endDateProps },
	      } = useMultiInputDateRangeField<any, MultiInputFieldSlotTextFieldProps>( {
		sharedProps        : {
			value                   : props.value,
			defaultValue            : props.defaultValue,
			format                  : props.format,
			onChange                : props.onChange,
			readOnly                : props.readOnly,
			disabled                : props.disabled,
			onError                 : props.onError,
			shouldDisableDate       : props.shouldDisableDate,
			minDate                 : props.minDate,
			maxDate                 : props.maxDate,
			disableFuture           : props.disableFuture,
			disablePast             : props.disablePast,
			selectedSections        : props.selectedSections,
			onSelectedSectionsChange: props.onSelectedSectionsChange,
		},
		startTextFieldProps: startTextFieldProps,
		endTextFieldProps  : endTextFieldProps,
		startInputRef      : startInputRef,
		endInputRef        : endInputRef,
	} );
	
	return (
		<Stack spacing={2} direction='row' width='fit-content'>
			<Box width='100%'>
				<TextFieldInputLabel
					isError={props.formattedTextFieldPropsStart?.isError}
					label={startDateProps.label}
					tooltip={props.formattedTextFieldPropsStart?.tooltip}
				/>
				<TextField
					{...startDateProps}
					{...props.formattedTextFieldPropsStart}
					label=''
					inputRef={startRef}
				/>
			</Box>
			<Box width='100%'>
				<TextFieldInputLabel
					isError={props.formattedTextFieldPropsEnd?.isError}
					label={endDateProps.label}
					tooltip={props.formattedTextFieldPropsEnd?.tooltip}
				/>
				<TextField
					{...endDateProps}
					{...props.formattedTextFieldPropsEnd}
					label=''
					inputRef={endRef}
				/>
			</Box>
		</Stack>
	);
} );

export default DateRangePickerFields;
