import { CompleteSplash } from '@/providers/splash';
import { Box, Container, Typography } from '@mui/material';
import type { GetServerSideProps } from 'next';

type Props = {
	title?: string,
	subtitle?: string
};

export default function ErrorPage( { title, subtitle }: Props ) {
	return (
		<Container maxWidth='xl'>
			<CompleteSplash/>
			<Box sx={{ textAlign: 'center', height: '100%', pt: 5 }}>
				<Typography variant='h1' fontWeight={500}>
					{title ?? 'Unknown'}
				</Typography>
				<Typography variant='h3' fontWeight='normal' color='text.secondary'>
					{subtitle ?? 'An error occurred.'}
				</Typography>
			</Box>
		</Container>
	);
}

// noinspection JSUnusedGlobalSymbols
export const getServerSideProps: GetServerSideProps = async ( { res } ) => {
	const statusCode = res?.statusCode || 'error';
	return { props: { statusCode } };
};

