import { MenuItem, TextField } from '@mui/material';
import { set } from 'lodash-es';
import type { ColumnInstance } from 'react-table';

export default function ArrayFilter( {
	filter,
	setFilter,
	column,
}: {
	column: ColumnInstance<any>,
	filter: { [ key: string ]: { [ nestedKey: string ]: { $ilike } } },
	setFilter: ( filterValue: any ) => void
} ) {
	const key = ( column.filterByKey || column.id ) as string;
	
	return (
		<TextField
			fullWidth
			select={Boolean( column.filterOptions )}
			variant='outlined'
			placeholder='Search '
			value={key.split( '.' )[ 2 ]
				? filter?.[ key.split( '.' )[ 1 ] ]?.[ key.split( '.' ).splice( 2 ).join( '.' ) ]?.$ilike
				: filter?.[ key.split( '.' )[ 1 ] ]?.$ilike
				?? ''}
			onChange={( e ) => setFilter( {
				...filter,
				[ key.split( '.' )[ 1 ] ]:
					key.split( '.' )[ 2 ]
						? set( {}, key.split( '.' ).splice( 2 ).join( '.' ), { $ilike: e.target.value } )
						: { $ilike: e.target.value },
			} )}>
			{column.filterOptions && column.filterOptions?.map( ( option ) => (
				<MenuItem key={option} value={option}>
					{option}
				</MenuItem>
			) )}
		</TextField>
	);
}
