import { useMenu } from '@/providers/menu';
import { Button, MenuItem, MenuList, Tooltip } from '@mui/material';
import { QuickActionsType } from '../index';

export default function QuickFiltersButton( { useQuickFilters }: { useQuickFilters: () => QuickActionsType[] } ) {
	const { showMenu } = useMenu();
	
	const items = useQuickFilters();
	
	if ( !items?.length ) return null;
	
	return (
		<Tooltip title='Apply Quick Filters'>
			<Button
				variant='outlined'
				onClick={( e ) => showMenu( ( { closeMenu } ) => (
					<MenuList sx={{ p: 0 }}>
						{items.filter( Boolean ).map( ( item, index ) => (
							<MenuItem
								key={index}
								onClick={() => {
									item.onClick();
									closeMenu();
								}}>
								{item.name}
							</MenuItem>
						) )}
					</MenuList>
				), e.currentTarget )}>
				Quick Filter
			</Button>
		</Tooltip>
	);
}

