import { mutateGraphQL } from '@/data/apollo';
import { StaffTableWrite } from '@/graphql/staff.graphql';
import useUserInfo from '@/providers/auth/useUserInfo';
import { MutationStaffTableWriteArgs } from '@/types/schema';
import { ViewWeek as ColumnIcon } from '@mui/icons-material';
import { Button, FormControlLabel, Menu, MenuItem, MenuList, Stack, Tooltip, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';
import CustomCheckBox from '../../customCheckBox';
import AsyncLoadingButton from '../../form/asyncLoading/asyncLoadingButton';

export default function ColumnsButton( {
	table,
	hiddenTableColumns,
}: {
	table: TableInstance,
	hiddenTableColumns: string
} ) {
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const queryClient = useQueryClient();
	
	const [ columnMenu, setColumnMenu ] = useState<Element>( null );
	
	const hiddenColumns = table.allColumns
		.filter( ( { id, isVisible } ) => id !== 'selection' && id !== 'expander' && !isVisible )
		.map( ( column ) => column?.id );
	
	return (
		<Fragment>
			<Tooltip title={t( 'common:columns' )}>
				<Button
					variant='outlined'
					startIcon={<ColumnIcon/>}
					onClick={( e ) => setColumnMenu( e.currentTarget )}>
					{t( 'common:columns' )}
				</Button>
			</Tooltip>
			<Menu
				anchorEl={columnMenu}
				open={Boolean( columnMenu )}
				sx={{ '& .MuiPaper-root': { width: 300 }, '.MuiList-root': { py: '0px !important' } }}
				onClose={( e: MouseEvent ) => {
					e.stopPropagation();
					setColumnMenu( null );
				}}>
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'
					sx={{
						p           : 1,
						position    : 'sticky',
						top         : 0,
						left        : 0,
						right       : 0,
						bgcolor     : 'background.paper',
						borderBottom: 1,
						borderColor : 'divider',
						zIndex      : 2,
					}}>
					<Typography>
						{t( 'common:columns' )}
					</Typography>
					{hiddenTableColumns && staff && (
						<AsyncLoadingButton
							variant='contained'
							color='primary'
							onClick={async () => {
								await mutateGraphQL<MutationStaffTableWriteArgs>( {
									mutation : StaffTableWrite,
									variables: {
										id   : staff.id,
										input: { metadata: { [ hiddenTableColumns ]: hiddenColumns } },
									},
								} );
								await queryClient.invalidateQueries( [ 'user' ] );
								setColumnMenu( null );
							}}>
							{t( 'common:save-columns' )}
						</AsyncLoadingButton>
					)}
				</Stack>
				<MenuList>
					{table.allColumns
						.filter( ( { id } ) => id !== 'selection' && id !== 'expander' )
						.map( ( column ) => {
							const headerName = typeof column.render( 'Header' ) === 'string'
								? t( column.render( 'Header' ).valueOf() as string )
								: column.render( 'Header' );
							
							return (
								<MenuItem key={column.id} onClick={() => column.toggleHidden()}>
									<FormControlLabel
										control={<CustomCheckBox/>}
										label={headerName}
										onClick={() => column.toggleHidden()}
										{...column.getToggleHiddenProps()}
									/>
								</MenuItem>
							);
						} )}
				</MenuList>
			</Menu>
		</Fragment>
	);
}

