import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import type { TextFieldProps } from '@mui/material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'rooks';

export default function TableSearchBar( {
	globalFilter,
	setGlobalFilter,
	...props
}: {
	globalFilter: string,
	setGlobalFilter: ( value: string ) => void
} & TextFieldProps ) {
	const [ value, setValue ] = useState( globalFilter );
	
	const onChange = useDebounce( ( value ) => setGlobalFilter( value || undefined ), 500 );
	
	const { t } = useTranslation();
	return (
		<TextField
			fullWidth
			sx={{
				'position'              : 'relative',
				'.MuiOutlinedInput-root': { height: 45, fontSize: '1.4rem !important', borderRadius: 2 },
			}}
			value={value || ''}
			size='medium'
			variant='outlined'
			placeholder={`${t( 'common:search' )}...`}
			InputProps={{
				startAdornment: (
					<InputAdornment position='start'>
						<SearchIcon/>
					</InputAdornment>
				),
				endAdornment  : value ? (
					<InputAdornment position='end'>
						<IconButton
							sx={{ p: 0 }}
							onClick={() => {
								setValue( '' );
								onChange( '' );
							}}>
							<ClearIcon/>
						</IconButton>
					</InputAdornment>
				) : null,
			}}
			onChange={( e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> ) => {
				setValue( e.target.value );
				onChange( e.target.value );
			}}
			{...props}
		/>
	);
}
