import { atom, useSetAtom } from 'jotai';
import { RefObject, useCallback, useEffect, useRef } from 'react';

export const scrollLeftAtom = atom( false );
export const scrollRightAtom = atom( false );
export const cellWidthAtom = atom( 160 );

const useHorizontalScrollShadow = ( ref: RefObject<HTMLDivElement> ) => {
	const setIsMoreThan5PercentAwayRight = useSetAtom( scrollRightAtom );
	const setIsMoreThan5PercentAwayLeft = useSetAtom( scrollLeftAtom );
	const animationFrameId = useRef<number | null>( null );
	
	const handleScrollCheck = useCallback( () => {
		if ( !ref.current ) return;
		const { scrollLeft, scrollWidth, clientWidth } = ref.current;
		const maxScrollLeft = scrollWidth - clientWidth;
		const percentage = scrollLeft / maxScrollLeft;
		
		if ( scrollWidth > clientWidth ) {
			setIsMoreThan5PercentAwayRight( percentage < 0.95 );
			setIsMoreThan5PercentAwayLeft( percentage > 0.05 );
		} else {
			setIsMoreThan5PercentAwayRight( false );
			setIsMoreThan5PercentAwayLeft( false );
		}
	}, [ ref, setIsMoreThan5PercentAwayRight, setIsMoreThan5PercentAwayLeft ] );
	
	useEffect( () => {
		// Initial check
		setTimeout( handleScrollCheck, 100 );
		
		const observer = new ResizeObserver( handleScrollCheck );
		
		if ( ref.current ) {
			observer.observe( ref.current );
		}
		
		// Periodic check to handle dynamic content changes
		const intervalId = setInterval( handleScrollCheck, 2000 );
		
		return () => {
			clearInterval( intervalId );
			if ( animationFrameId.current !== null ) {
				cancelAnimationFrame( animationFrameId.current );
			}
			if ( ref.current ) {
				observer.unobserve( ref.current );
			}
		};
	}, [ handleScrollCheck, ref ] );
	
	return { handleScrollCheck };
};

export default useHorizontalScrollShadow;
