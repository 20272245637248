import { localCompanyIdAtom, sessionCompanyIdAtom } from '@/utils/atoms';
import { useAtom } from 'jotai';

type UseCompanyId = {
	sessionCompanyId: string | null,
	localCompanyId: string | null,
	setCompanyId: ( companyId: string | null ) => void,
	deleteCompanyId: () => void
};

export function useCompanyId(): UseCompanyId {
	const [ sessionCompanyId, setSessionCompanyId ] = useAtom( sessionCompanyIdAtom );
	const [ localCompanyId, setLocalCompanyId ] = useAtom( localCompanyIdAtom );
	
	const setCompanyId = ( companyId: string ): void => {
		if ( typeof window === 'undefined' || !( 'sessionStorage' in window ) ) {
			return;
		}
		sessionStorage.setItem( 'companyId', companyId );
		setSessionCompanyId( companyId );
		
		localStorage.setItem( 'companyId', companyId );
		setLocalCompanyId( companyId );
		
	};
	
	const deleteCompanyId = (): void => {
		if ( typeof window === 'undefined' || !( 'sessionStorage' in window ) ) {
			return;
		}
		sessionStorage.removeItem( 'companyId' );
		localStorage.removeItem( 'companyId' );
		setSessionCompanyId( null );
		setLocalCompanyId( null );
	};
	
	return { sessionCompanyId, localCompanyId, setCompanyId, deleteCompanyId };
}
