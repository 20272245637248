import { HelpCenterRounded as HelpCenterRoundedIcon } from '@mui/icons-material';
import { Box, InputLabel, InputLabelProps, Stack, Tooltip } from '@mui/material';
import type { ReactNode } from 'react';

export interface TextFieldInputLabelProps extends InputLabelProps {
	label: ReactNode,
	tooltip?: ReactNode,
	isError?: boolean,
	labelEndNode?: ReactNode
}

export default function TextFieldInputLabel( {
	label,
	tooltip,
	isError,
	labelEndNode,
	...props
}: TextFieldInputLabelProps ) {
	if ( !label ) return null;
	return (
		<Stack direction='row' alignItems='center' pb={.5} justifyContent='space-between'>
			<Stack direction='row' alignItems='center' spacing={1}>
				<InputLabel
					sx={{
						fontWeight: 500,
						color     : isError ? 'red' : 'text.primary',
						pl        : .5,
						...props.sx,
					}}
					{...props}>
					{label}
				</InputLabel>
				{tooltip && (
					<Tooltip title={tooltip}>
						<HelpCenterRoundedIcon
							sx={{
								fontSize    : 16,
								borderRadius: 1,
								color       : 'text.secondary',
							}}
						/>
					</Tooltip>
				)}
			</Stack>
			<Box>
				{labelEndNode}
			</Box>
		</Stack>
	);
}
