import { mutateGraphQL } from '@/data/apollo';
import axios from 'axios';
import { validate } from 'uuid';
import { ItemsWrite } from '../../../../data/management/item.graphql';
import idPick from '../../../../helpers/idPick';
import { Item, MutationItemsWriteArgs } from '../../../../types/schema';
import wait from '../../../../utils/wait';

export default async function saveAndPostItems( items: Item[] ) {
	await mutateGraphQL<MutationItemsWriteArgs>( {
		mutation : ItemsWrite,
		variables: {
			inputs: items?.map( ( item: Item ) => ( {
				id           : validate( item.id ) ? item.id : undefined,
				name         : item.name,
				description  : item.description,
				type         : item.type,
				isInventory  : item.isInventory,
				taxable      : item.taxable,
				categories   : item.categories?.[ 0 ] ? item.categories.map( ( { id } ) => id ) : [],
				limitReminder: item.limitReminder,
				uoms         : item.uoms?.length ? item.uoms.map( ( uom, index ) => ( {
					...idPick( uom, [
						'selected',
						'name',
						'price',
						'vendorSku',
						'cost',
						'sku',
						'code',
					] ),
					quantity: +uom?.quantity || 1,
					sequence: uom?.sequence || index,
				} ) ) : [],
			} ) ),
		},
	} );
	try {
		const postItems = Promise.all( items.map( async ( item ) => axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/postItem`, {
			id         : item.id,
			gatewayData: item.gateway || item.locations[ 0 ]?.gateway,
			item,
			withItem   : true,
		} ) ) );
		// wait at least 1 second to avoid rate limiting
		await Promise.all( [ wait( 1000 ), postItems ] );
		return true;
	} catch {
		return false;
	}
}
