import { FormControl, MenuItem, Select, Stack, TextField } from '@mui/material';
import { DatePicker, DateRangePicker } from '@mui/x-date-pickers-pro';
import { endOfDay, startOfDay, sub } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { defaults } from 'lodash-es';
import { useEffect, useState } from 'react';
import { getBrowserTimezone } from '../../../utils/timezone';
import DateRangePickerFields from '../../muiDatePickerFields/dateRangePickerSlot';
import DateTextFieldSlot from '../../muiDatePickerFields/dateTextFieldSlot';

export default function DateFilter( { filter, setFilter } ) {
	const timezone = getBrowserTimezone();
	const [ shouldUpdate, setShouldUpdate ] = useState( true );
	
	const initialFilterState = {
		operatorValue        : 'equalTo',
		startDate            : null,
		endDate              : null,
		dayMonthSelectorValue: 'days',
		dayMonthValue        : '',
	};
	
	const state = defaults( filter?.temp, initialFilterState );
	
	useEffect( () => {
		if ( !shouldUpdate ) return;
		
		const startDateInTimeZone = filter?.temp?.startDate
			? utcToZonedTime( filter.temp.startDate, timezone )
			: startOfDay( utcToZonedTime( new Date(), timezone ) );
		const endDateInTimeZone = endOfDay( startDateInTimeZone );
		
		const newFilter = { ...filter, temp: { ...filter?.temp, operatorValue: state.operatorValue } };
		
		switch ( state.operatorValue ) {
			case 'equalTo':
				newFilter.$gte = startDateInTimeZone.toISOString();
				newFilter.$lte = endDateInTimeZone.toISOString();
				break;
			case 'lessThan':
				newFilter.$lt = startDateInTimeZone.toISOString();
				break;
			case 'moreThan':
				newFilter.$gt = startDateInTimeZone.toISOString();
				break;
			case 'range':
				newFilter.$gte = startDateInTimeZone.toISOString();
				newFilter.$lte = filter.$lte
					? endOfDay( utcToZonedTime( filter.$lte, timezone ) )
					: endOfDay( utcToZonedTime( new Date(), timezone ) ).toISOString();
				break;
			default:
				break;
		}
		
		setFilter( newFilter );
		setShouldUpdate( false );
	}, [ state.operatorValue, filter?.temp?.operatorValue, filter?.temp?.startDate ] );
	
	const handleOperatorChange = ( e ) => {
		setShouldUpdate( true );
		setFilter( {
			temp: { ...state, operatorValue: e.target.value },
		} );
	};
	
	const handleDayMonthValueChange = ( e ) => {
		setShouldUpdate( true );
		setFilter( {
			temp: {
				...state,
				dayMonthSelectorValue: state.dayMonthSelectorValue,
				dayMonthValue        : e.target.value,
			},
			$gte: startOfDay( sub( utcToZonedTime( new Date(), timezone ), { [ state.dayMonthSelectorValue ]: Number( e.target.value ) } ) )
				.toISOString(),
			$lte: endOfDay( utcToZonedTime( new Date(), timezone ) ).toISOString(),
		} );
	};
	
	const handleDayMonthSelectorChange = ( e ) => {
		setShouldUpdate( true );
		setFilter( {
			temp: { ...state, dayMonthSelectorValue: e.target.value },
			$gte: startOfDay( sub( utcToZonedTime( new Date(), timezone ), { [ e.target.value ]: Number( state.dayMonthValue ) } ) )
				.toISOString(),
			$lte: endOfDay( utcToZonedTime( new Date(), timezone ) ).toISOString(),
		} );
	};
	
	return (
		<Stack spacing={1}>
			<FormControl sx={{ minWidth: 80 }}>
				<Select autoWidth value={state.operatorValue} onChange={handleOperatorChange}>
					<MenuItem value='lessThan'>Is Less than</MenuItem>
					<MenuItem value='equalTo'>Is Equal To</MenuItem>
					<MenuItem value='range'>Range</MenuItem>
					<MenuItem value='moreThan'>Is More Than</MenuItem>
					<MenuItem value='last days'>In the last ... days / Months</MenuItem>
				</Select>
			</FormControl>
			{state.operatorValue === 'last days' && (
				<Stack direction='row' sx={{ alignItems: 'center' }}>
					<TextField
						placeholder='ex. 7'
						variant='outlined'
						value={state.dayMonthValue}
						onChange={handleDayMonthValueChange}
					/>
					<FormControl sx={{ m: 1, minWidth: 80 }}>
						<Select autoWidth value={state.dayMonthSelectorValue} onChange={handleDayMonthSelectorChange}>
							<MenuItem value='days'>days</MenuItem>
							<MenuItem value='months'>months</MenuItem>
						</Select>
					</FormControl>
				</Stack>
			)}
			{[ 'equalTo', 'lessThan', 'moreThan' ].includes( state.operatorValue ) && (
				<DatePicker<Date>
					label='Select start date'
					value={state.startDate ? new Date( state.startDate ) : null}
					slots={{ textField: DateTextFieldSlot }}
					slotProps={{ dialog: { style: { zIndex: 9999 } } }}
					onChange={( newValue ) => {
						const startDate = startOfDay( utcToZonedTime( newValue, timezone ) );
						const endDate = endOfDay( startDate );
						setShouldUpdate( true );
						setFilter( {
							...filter,
							temp: { ...state, startDate, endDate },
							...state.operatorValue === 'lessThan' && { $lt: startDate.toISOString() },
							...state.operatorValue === 'moreThan' && { $gt: startDate.toISOString() },
							...state.operatorValue === 'equalTo' && {
								$gte: startDate.toISOString(),
								$lte: endDate.toISOString(),
							},
						} );
					}}
				/>
			)}
			{state.operatorValue === 'range' && (
				<DateRangePicker
					value={[ state.startDate ? new Date( state.startDate ) : null,
					         state.endDate ? new Date( state.endDate ) : null ]}
					slots={{ field: DateRangePickerFields }}
					slotProps={{ dialog: { style: { zIndex: 9999 } } }}
					onChange={( newValue ) => {
						if ( newValue.filter( Boolean ).length === 2 ) {
							const startDate = startOfDay( utcToZonedTime( newValue[ 0 ], timezone ) );
							const endDate = endOfDay( utcToZonedTime( newValue[ 1 ], timezone ) );
							setShouldUpdate( true );
							setFilter( {
								...filter,
								temp: { ...state, startDate, endDate },
								$gte: startDate.toISOString(),
								$lte: endDate.toISOString(),
							} );
						}
					}}
				/>
			)}
		</Stack>
	);
}
