import { Autocomplete, TextField } from '@mui/material';
import { set } from 'lodash-es';
import type { ColumnInstance } from 'react-table';

export default function OptionsFilter( { filter, setFilter, column }: {
	column: ColumnInstance<any>,
	filter: { [ key: string ]: { [ nestedKey: string ]: { $eq } } },
	setFilter: ( filterValue: any ) => void
} ) {
	const key = ( column.filterByKey ) as string; // example: payments.type in invoice table
	const firstFilter = key?.split( '.' )?.[ 1 ];
	const secondFilter = key?.split( '.' )?.[ 2 ];
	
	const setFilterValue = ( value ) => {
		setFilter( {
			...filter,
			...!key ? { $eq: value } : {
				[ firstFilter ]:
					secondFilter
						? set( {}, key.split( '.' ).splice( 2 ).join( '.' ), { $eq: value } )
						: { $eq: value },
			},
		} );
	};
	
	return (
		<Autocomplete
			disablePortal
			freeSolo
			options={column.filterOptions}
			value={!key ? filter?.$eq ?? '' : secondFilter
				? filter?.[ firstFilter ]?.[ key.split( '.' ).splice( 2 ).join( '.' ) ]?.$eq
				: filter?.[ firstFilter ]?.$eq ?? ''}
			sx={{ width: 300 }}
			renderInput={( params ) => <TextField {...params} label='Type'/>}
			onInputChange={( e, value ) => {
				setFilterValue( value );
			}}
			onChange={( e, value ) => {
				setFilterValue( value );
			}}
		/>
	);
}
