import { Typography } from '@mui/material';
import { useEffect } from 'react';

export default function ExistFilter( {
	filter,
	setFilter,
	column,
}: {
	filter: { [ key: string ]: { $ne }, temp },
	setFilter: ( filterValue: any ) => void,
	column
} ) {
	const filterByKey = column?.filterByKey;
	const filterKey = filterByKey?.split( '.' )?.[ 1 ];
	
	useEffect( () => {
		if ( !filter?.hasOwnProperty( '$ne' ) || filter?.[ filterKey ].$ne === undefined ) {
			setFilter( { ...filter, [ filterKey ]: { $ne: null } } );
		}
	}, [ filter?.temp ] );
	
	return (
		<Typography>Exists</Typography>
	);
}
