/* eslint-disable react/jsx-key */

import type { ActionProps } from '@/components/actions';
import { ActionType } from '@/components/graphqlTable/index';
import { scrollLeftAtom, scrollRightAtom } from '@/hooks/useScroll';
import { TableCell, TableFooter as _TableFooter, TableRow } from '@mui/material';
import { useAtomValue } from 'jotai/index';
import type { ReactNode } from 'react';
import type { TableInstance } from 'react-table';

export default function TableFooter( {
	table,
	showCheckBox,
	expandedComponent,
	useActions,
	actionCellWidth,
}: {
	table: TableInstance<any>,
	showCheckBox: boolean,
	expandedComponent: ( row ) => ReactNode,
	useActions: ( row ) => ActionProps[] | ActionType,
	actionCellWidth?: number
} ) {
	return (
		<_TableFooter component='div'>
			{table.footerGroups.map( ( group, index ) => (
				<TableRow component='div' {...group.getFooterGroupProps()} key={index}>
					{Boolean( showCheckBox && expandedComponent ) && (
						<CheckBoxCell/>
					)}
					<TableCell key={index} sx={{ width: 46 }} component='div'/>
					{group.headers.map( ( column, index ) => (
						<TableCell component='div' {...column.getFooterProps()} key={index}>
							{column.render( 'Footer' ) as ReactNode}
						</TableCell>
					) )}
					{Boolean( useActions ) && <ActionCell actionCellWidth={actionCellWidth}/>}
				</TableRow>
			) )}
		</_TableFooter>
	);
}

function CheckBoxCell() {
	const isMoreThan5PercentAwayLeft = useAtomValue( scrollLeftAtom );
	return (
		<TableCell
			sx={{
				width     : '50px !important',
				position  : 'sticky',
				top       : 0,
				left      : 0,
				zIndex    : 3,
				bgcolor   : 'background.default',
				filter    : isMoreThan5PercentAwayLeft
					? 'drop-shadow(rgba(0, 0, 0, 0.3) 2px 10px 6px)'
					: 'none',
				transition: 'filter 0.3s ease-out',
			}}
		/>
	);
}

function ActionCell( { actionCellWidth } ) {
	const isMoreThan5PercentAwayRight = useAtomValue( scrollRightAtom );
	
	return (
		<TableCell
			sx={{
				width     : actionCellWidth,
				position  : 'sticky',
				right     : 0,
				zIndex    : 3,
				bgcolor   : 'background.default',
				transition: 'filter 0.3s ease-out',
				filter    : isMoreThan5PercentAwayRight
					? 'drop-shadow(rgba(0, 0, 0, 0.3) -2px 10px 6px)'
					: 'none',
			}}
		/>
	);
}
